<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type { GiftDetailsSideSlideProps } from './GiftDetailsSideSlide.props'
const { promotionGifts } = defineProps<GiftDetailsSideSlideProps>()
</script>

<template class="">
  <OrganismsSidebarSlide
    :id="SIDEBAR_IDS.giftDetailsSideSlide"
    :header-props="{ titleText: $ts('miniCart.giftSideTitle') }"
  >
    <template #body>
      <div
        v-for="gift in promotionGifts"
        :key="gift?.id"
        class="bg-neutral-white mx-auto flex flex-col"
      >
        <NuxtPicture
          :src="gift?.image_url!"
          :alt="gift?.name ?? ''"
          provider="cloudinary"
          class="aspect-pdp-image object-contain"
        />

        <div class="relative flex flex-col gap-y-2 pt-10">
          <p class="text-book-6">{{ gift?.name }}</p>
          <p class="text-light-6">
            {{ gift?.description }}
          </p>
        </div>
      </div>
    </template>
  </OrganismsSidebarSlide>
</template>
